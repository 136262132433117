<template>
  <div class="mobile-list">
    <div class="mobile-list-top flex-col" :style='bg'>

      <div class="flex-row fill-row ">
        <Menu mode="horizontal" theme="primary" active-name="1" @on-select="menuclick">
          <MenuItem name="1" style="width: 33%;">
          <Icon />
          我的名片
          </MenuItem>
          <MenuItem name="2" style="width: 33%;">
          <Icon />
          我的任务
          </MenuItem>
          <MenuItem name="3" style="width: 33%;">
          <Icon />
          我的业绩
          </MenuItem>
        </Menu>
      </div>
      <div class="flex-row  margin-buttom-10 ">
        <div class="fill-row3"> <a> <img src="@/static/images/icon_Avatar.png" class="img" alt /></a></div>
        <div class="flex-col fill-row7">
          <label class="text-left margin-top-10 white-font-color">成涛</label>
          <label class="text-left margin-top-10 white-font-color">开发部：.net开发工程师</label>
        </div>
      </div>
    </div>
    <div class="mobile-detail">
      <div class="mobile-detail-container">
        <cube-scroll>
          <inputItem title="部门" initialContent="部门" :disabled="disabled"><img src="@/static/images/icon_back.png" width="20px" height="20px"></inputItem>
          <inputItem title="职位" initialContent=".net开发工程师" :disabled="disabled">></inputItem>
          <inputItem title="手机号" initialContent="13212707473" :disabled="disabled">></inputItem>
          <inputItem title="邮箱" initialContent="979344687@qq.com" :disabled="disabled"></inputItem>
          <inputItem title="入职时间" initialContent="2021-04-28" :disabled="disabled"></inputItem>
        </cube-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import bg_img from "@/static/images/bg_Rectangle.png"
export default {

  data: function () {
    return {
      theme1: 'light',
      disabled: true,
      bg: {
        background: `url(${bg_img})`,
        backgroundSize: '100% 100%'
      },
    };
  },
  methods: {
    menuclick(name) {
      let url = ""
      if (name == "1") {
        url = "mycard";
      } else if (name == "2") {
        url = "mytask";
      } else if (name == "3") {
        url = "myperformance";
      }
      this.$router.push({
        name: url,
      });
    },
    click(i) {
      console.log(i)
    }
  },
  mounted: function () {

  },
};
</script>

<style lang="scss" scoped>
.mobile-input-disabled {
  .title {
    margin-left: 5px !important;
    padding-left: 6px !important;
    text-align: left;
  }
}
.ivu-menu-primary {
  width: 100%;
}
</style>
<style scoped>
.ivu-menu-item-active {
  color: white;
  border-bottom: 2px solid white;
}
.ivu-menu-primary {
  background: none;
}
.img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
</style>